import React, { Component } from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { FacebookProvider, Page } from 'react-facebook';


class NewsPage extends Component {

	componentDidMount() {
	}

	render() {


		return(
		<Layout>
		<SEO title="Actualités" description="Dernières publications sur Facebook" />
	
        <section className="module" >
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="m-title c-align">
                        <h1>Actualités</h1>
                        <h6>Retrouvez nos dernières actualités sur <a href="https://www.facebook.com/herve.cohonner">Facebook</a></h6>
                    </div>
                </div>
            </div>
        </div>
        <FacebookProvider appId="114920789733" language="fr_FR">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
         
                    <Page href="https://www.facebook.com/herve.cohonner" tabs="timeline" height="1000" adaptContainerWidth="true"/>

              
            </div></div></div>
            </FacebookProvider>        

        </section>




	  </Layout>)
	}
}




NewsPage.propTypes = {
	
}

export default NewsPage
